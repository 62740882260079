import { Chip, Tooltip, IconButton, Grid, Divider, FormControlLabel, Box, Skeleton, Button, FormLabel } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { useState, useEffect } from "react";
import { useNavigate, useLocation, NavLink } from "react-router-dom";
import { fetchMyDepragApi } from "../../../../../Infrastructure/Api/ApiBaseCall";
import { LanguageConsumer, getTranslation } from "../../../../../Infrastructure/Internationalisation/TranslationService"
import { useAppDispatch, useAppSelector } from "../../../../../Infrastructure/Store/hooks";
import { store, RootState } from "../../../../../Infrastructure/Store/store";
import { CustomSwitch } from "../../../../../Layout/Switch/CustomSwitch";
import { GRID_DEFAULT_LOCALE_TEXT_DE } from "../../../../../assets/DataGridLocalisation/DataGridLocale_de";
import { GRID_DEFAULT_LOCALE_TEXT_EN } from "../../../../../assets/DataGridLocalisation/DataGridLocale_en";
import { GridColDef, GridRenderCellParams, GridRowParams } from '@mui/x-data-grid';
import { IoPencilSharp } from "react-icons/io5";
import { CustomerOverviewResponseDto } from "../Models/CustomerOverviewResponseDto";
import { CustomerOverviewItemDto } from "../Models/CustomerOverviewItemDto";
import { toggleShowAllCustomers } from "../../Store/CustomersSlice";
import { AddCircleOutline, Delete, Label } from "@mui/icons-material";
import { StripedDataGrid } from "../../../../../Layout/DataGrid/StripedDataGrid";
import { DataGridSearch } from "../../../../../Layout/DataGrid/DataGridSearch";
import { FiInfo } from "react-icons/fi";
import { AllCustomersTableDrawer } from "./AllCustomersTableDrawer";
import { AllCustomersDeleteDialog } from "./AllCustomersDeleteDialog";


export const AllCustomersTable = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const language = useAppSelector(store => store.settings.language);
    const location = useLocation();
    const { isDepragAmbergMember, isReseller } = useAppSelector(store => store.authentication);
    const [searchValue, setSearchValue] = useState<string>("");
    const showAllCustomers = useAppSelector(store => store.customers.showAllCustomers);
    const [openDrawer, setOpenDrawer] = useState<boolean>(false)
    const [selectedCustomerId, setSelectedCustomerId] = useState<number>(0)
    const customerId = useAppSelector(store => store.authentication.customerId);

    const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false)

    const { isRefetching, isLoading, data, refetch } = useQuery({
        queryKey: ["customers"],
        queryFn: async () => await fetchMyDepragApi<CustomerOverviewResponseDto>(
            { showAll: isDepragAmbergMember ? showAllCustomers : true, preferredLanguageIso: language },
            "customers/overview/" + customerId,
            'POST',
            dispatch,
            store.getState() as RootState)
    });

    useEffect(() => {
        refetch();
    }, [location, refetch, showAllCustomers]);

    const search = (inputText: string) => {
        setSearchValue(inputText);
    }

    const showAllCustomersChanged = (value: boolean) => {
        dispatch(toggleShowAllCustomers());
    }

    const openDetails = (params: GridRowParams) => {
        navigate(`/customers/edit/${params.row.Id}`)
    }

    const onClickDetailDrawer = (id: number) => {
        setSelectedCustomerId(id)
        setOpenDrawer(true)
    }

    const onClickDeletingCustomerDialog = (id: number) => {
        setSelectedCustomerId(id)
        setOpenDeleteDialog(true)
    }

    const columns: GridColDef[] = [
        {
            field: 'Id',
            headerName: "Id",
            flex: 0,
            minWidth: 0,
        },
        {
            field: 'CompanyName',
            headerName: getTranslation("CustomerCompanyName"),
            flex: 1,
            minWidth: 250,
        },
        {
            field: 'InternalId',
            headerName: getTranslation("CustomerInternalId"),
            flex: 1,
            minWidth: 110,
            renderCell: (params: GridRenderCellParams<any, CustomerOverviewItemDto, any>) => {
                return params.row.InternalId ? <Chip variant={"outlined"} label={params.row.InternalId} /> : <></>
            }
        },
        {
            field: 'SapCustomerId',
            headerName: getTranslation("CustomerSapCustomerId"),
            flex: 1,
            minWidth: 150,
            renderCell: (params: GridRenderCellParams<any, CustomerOverviewItemDto, any>) => {
                return params.row.SapCustomerId ? <Chip label={params.row.SapCustomerId} /> : <></>
            }
        },
        {
            field: 'CountryName',
            headerName: getTranslation("CustomerCountryName"),
            flex: 1,
            minWidth: 150,
        },
        {
            field: 'City',
            headerName: getTranslation("CustomerCity"),
            flex: 1,
            minWidth: 150,
        },
        {
            field: 'NumberOfUsers',
            headerName: getTranslation("CustomerNumberOfUsers"),
            flex: 1,
            minWidth: 120,
        },
        {
            field: 'NumberOfActivGoldLicences',
            headerName: getTranslation("CustomerNumberOfActivGoldLicences"),
            flex: 1,
            minWidth: 120,
        },
        {
            field: 'DetailsDrawer',
            headerName: '',
            flex: 0.1,
            minWidth: 60,
            align: 'center',
            renderCell: (params: GridRenderCellParams<any, CustomerOverviewItemDto, any>) => (
                <strong>
                    <Tooltip title={getTranslation("Details")}>
                        <IconButton color={'darky'} onClick={(e) => onClickDetailDrawer(params.row.Id)}>
                            <FiInfo />
                        </IconButton>
                    </Tooltip>
                </strong>
            ),
            sortable: false,
            filterable: false
        },
        {
            field: '',
            headerName: '',
            flex: 0.1,
            minWidth: 60,
            align: 'center',
            renderCell: (params: GridRenderCellParams<any, CustomerOverviewItemDto, any>) => (
                <strong>
                    <Tooltip title={getTranslation("CommonEdit")}>
                        <IconButton color={'darky'} component={NavLink} to={`/customers/edit/${params.row.Id}`}>
                            <IoPencilSharp />
                        </IconButton>
                    </Tooltip>
                </strong>
            ),
            sortable: false,
            filterable: false
        },
        {
            field: 'DeleteCustomer',
            headerName: '',
            flex: 0.1,
            minWidth: 60,
            align: 'center',
            renderCell: (params: GridRenderCellParams<any, CustomerOverviewItemDto, any>) => (
                <strong>
                    <Tooltip title={getTranslation("Delete")}>
                        <IconButton color={'darky'} onClick={(e) => onClickDeletingCustomerDialog(params.row.Id)}
                            disabled={params.row.NumberOfUsers > 0 || params.row.NumberOfActiveGoldLicences > 0 || params.row.SapCustomerId != null}
                            >
                            <Delete />
                        </IconButton>
                    </Tooltip>
                </strong>
            ),
            sortable: false,
            filterable: false
        },
    ];

    return (
        <LanguageConsumer>
            {({ getTranslatedText }) =>
                <>
                    <Grid container direction="row" spacing={2}>
                        <Grid item xs={12} textAlign='center'>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sx={{ textAlign: 'left' }}>

                                    <div
                                        dangerouslySetInnerHTML={{ __html: getTranslatedText("CustomerInfoText") }}
                                    />

                                    <Divider sx={{ my: 3, mb: 2 }} />
                                </Grid>

                                <Grid item xs={4} my={'auto'} sx={{ textAlign: 'left', overflowX: 'hidden' }}>
                                    <DataGridSearch searchValue={searchValue} search={search} />
                                </Grid>

                                <Grid item xs={8} my={'auto'} sx={{ textAlign: 'right' }}>
                                    {(isDepragAmbergMember) && <>
                                        {isRefetching && <FormLabel sx={{ mr: '30px', mt: '8px', color: 'rgba(0, 0, 0, 0.87)', lineHeight: 1.5 }}>{getTranslatedText("RefetchTableData")}</FormLabel>}
                                        <FormControlLabel control={<CustomSwitch />} checked={showAllCustomers} onChange={(e, value) => showAllCustomersChanged(value)} label={getTranslatedText("ShowAllCustomers")} />
                                    </>
                                    }
                                    <Button startIcon={<AddCircleOutline />} sx={{ ml: 1 }} component={NavLink} to={'/customers/create'} variant="contained" color="success">{getTranslatedText("CreateCustomer")}</Button>
                                </Grid>

                            </Grid>
                            <Grid item xs={12} sx={{ mt: 1 }}>
                                <Box component={'div'} sx={{ width: '100%' }}>
                                    <StripedDataGrid
                                        localeText={language === "de" ? GRID_DEFAULT_LOCALE_TEXT_DE : GRID_DEFAULT_LOCALE_TEXT_EN}
                                        rows={data ? data.Items.filter(x =>
                                        (x.CompanyName && x.CompanyName?.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase()) ||
                                            (x.Id && x.Id.toString().trim().length > 0 && x.Id.toString().toLocaleLowerCase().includes(searchValue.toLocaleLowerCase())) ||
                                            (x.SapCustomerId && x.SapCustomerId.trim().length > 0 && x.SapCustomerId?.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase())) ||
                                            (x.City && x.City.trim().length > 0 && x.City?.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase())) ||
                                            x.CountryName?.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase())
                                        )
                                        ) : []}
                                        autoHeight={true}
                                        columns={columns}
                                        pageSize={8}
                                        rowsPerPageOptions={[8]}
                                        checkboxSelection={false}
                                        disableColumnFilter={false}
                                        disableColumnMenu={false}
                                        disableSelectionOnClick={true}
                                        disableVirtualization={false}
                                        hideFooter={false}
                                        getRowId={(x) => x.Id}
                                        onRowDoubleClick={openDetails}
                                        getRowClassName={(params) =>
                                            params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
                                        }
                                        components={{
                                            LoadingOverlay: () => <Skeleton variant="rectangular" height={100} sx={{ my: 4, mx: 1, mt: 1 }} />,
                                        }}
                                        loading={isLoading}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>

                    <AllCustomersTableDrawer open={openDrawer} onClose={() => setOpenDrawer(false)} customerId={selectedCustomerId} />

                    <AllCustomersDeleteDialog open={openDeleteDialog} onClose={() => setOpenDeleteDialog(false)} customerId={selectedCustomerId} refetch={refetch}/>
                </>
            }
        </LanguageConsumer>
    )
}
