import { Routes, Route, useLocation } from "react-router-dom";
import ProtectedRoute from "./Features/Infrastructure/Login/ProtectedRoute/ProtectedRoute";
import { Dashboard } from "./Features/MainMenus/Dashboard/Dashboard";
import { AnimatePresence } from "framer-motion";
import { Imprint } from "./Features/MainMenus/Imprint/Imprint";
import { Privacy } from "./Features/MainMenus/Privacy/Privacy";
import { ThirdParty } from "./Features/MainMenus/ThirdParty/ThirdParty";
import { AllCustomers } from "./Features/MainMenus/Customers/AllCustomers/AllCustomers";
import { AllUsers } from "./Features/MainMenus/Users/AllUsers/AllUsers";
import { CustomerEdit } from "./Features/MainMenus/Customers/CustomerEdit/CustomerEdit";
import { OpenRegistrations } from "./Features/MainMenus/Users/OpenRegistrations/OpenRegistrations";
import { CustomerCreate } from "./Features/MainMenus/Customers/CustomerCreate/CustomerCreate";
import { UserEdit } from "./Features/MainMenus/Users/UserEdit/UserEdit";
import {
  WaitForEmailVerifications,
} from "./Features/MainMenus/Users/WaitForEmailVerifications/WaitForEmailVerifications";
import { AllCourses } from "./Features/MainMenus/OnlineTraining/AllCourses/AllCourses";
import { CourseEdit } from "./Features/MainMenus/OnlineTraining/CourseCreateOrEdit/CourseEdit";
import { DeactivateByDevice } from "./Features/MainMenus/Licences/DeactivateByDevice/DeactivateByDevice";
import { ByOrderNumber } from "./Features/MainMenus/Licences/ByOrderNumber/ByOrderNumber";
import {
  PaidLicencesDetails,
} from "./Features/MainMenus/Licences/ByOrderNumber/Components/PaidLicencesDetails/PaidLicencesDetails";
import { HelpOverview } from "./Features/MainMenus/Help/HelpOverview/HelpOverview";
import { HelpCreateOrEdit } from "./Features/MainMenus/Help/HelpCreateOrEdit/HelpCreateOrEdit";
import { SettingsReminder } from "./Features/MainMenus/Settings/Reminder/SettingsReminder";
import { AllClaims } from "./Features/MainMenus/Highlevel/Claims/Overview/AllClaims";
import { ClaimsEdit } from "./Features/MainMenus/Highlevel/Claims/Details/ClaimsEdit";
import { Logs } from "./Features/MainMenus/Highlevel/ShowLogs/Overview/Logs";
import { LogsDetails } from "./Features/MainMenus/Highlevel/ShowLogs/Overview/Components/LogsDetails";
import { Maintenance } from "./Features/MainMenus/Highlevel/Maintenance/Overview/Maintenance";
import {
  MaintenanceMessageFormEdit,
} from "./Features/MainMenus/Highlevel/Maintenance/Details/Components/MaintenanceMessageFormEdit";
import {
  MaintenanceMessageFormAdd,
} from "./Features/MainMenus/Highlevel/Maintenance/Details/Components/MaintenanceMessageFormAdd";
import {
  EmailTriggerRulesOverview,
} from "./Features/MainMenus/Highlevel/EmailTriggerRules/Overview/EmailTriggerRulesOverview";
import {
  EmailTriggerRulesCreateOrEdit,
} from "./Features/MainMenus/Highlevel/EmailTriggerRules/CreateOrEdit/EmailTriggerRulesCreateOrEdit";
import { ByCompany } from "./Features/MainMenus/Licences/ByCompany/ByCompany";
import { ByCompanyAssign } from "./Features/MainMenus/Licences/ByCompany/Assign/ByCompanyAssign";
import { ExpertSettings } from "./Features/MainMenus/Highlevel/ExpertSettings/ExpertSettings";
import { Reseller } from "./Features/MainMenus/Productmanagment/Reseller/Reseller";
import { ResellerEdit } from "./Features/MainMenus/Productmanagment/Reseller/Components/ResellerEdit";
import { LegalDocuments } from "./Features/MainMenus/Productmanagment/LegalDocuments/LegalDocuments";
import { LegalDocumentsDetail } from "./Features/MainMenus/Productmanagment/LegalDocuments/Components/LegalDocumentsDetail";
import LegalDocumentsAdd from "./Features/MainMenus/Productmanagment/LegalDocuments/Components/LegalDocumentsAdd";
import { ResellerAdd } from "./Features/MainMenus/Productmanagment/Reseller/Components/ResellerAdd";
import { ProductSearch } from "./Features/MainMenus/Productmanagment/ProductSearch/ProductSearch";
import { CockpitCloudAreasOverview } from "./Features/MainMenus/Software/CockpitCloud/Overview/CockpitCloudAreasOverview";
import { MyGold } from "./Features/MainMenus/Licences/MyGold/MyGold";
import { MyGoldAssign } from "./Features/MainMenus/Licences/MyGold/Assign/MyGoldAssign";
import  usePersistStore  from "./Infrastructure/Hooks/Global/userPersistStore";
import { useEffect } from "react";
import { Reporting } from "./Features/MainMenus/Highlevel/Reporting/Reporting";
import { ServiceRequests } from "./Features/MainMenus/ServiceRequests/ServiceRequests";
import { ServiceRequestsDetail } from "./Features/MainMenus/ServiceRequests/Components/Details/ServiceRequestsDetails";
import { EquipmentByCompany } from "./Features/MainMenus/InstalledBase/ByCompany/EquipmentByCompany";
import { EquipmentByCompanyAssign } from "./Features/MainMenus/InstalledBase/ByCompany/Assign/EquipmentByCompanyAssign";

export const DepragRoutesContent = () => {
  const location = useLocation();

  const { repairPersistStoreIfNeeded } = usePersistStore();

  useEffect(() => {
    repairPersistStoreIfNeeded();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AnimatePresence>
      <Routes location={location} key={location.pathname}>

        <Route element={<ProtectedRoute />}>

          <Route path="/" element={<Dashboard />} />

          <Route path="/customers" element={<AllCustomers />} />
          <Route path="/customers/edit/:id" element={<CustomerEdit />} />
          <Route path="/customers/create" element={<CustomerCreate />} />

          <Route path="/resellers" element={<Reseller />} />
          <Route path="/resellers/edit/:id" element={<ResellerEdit />} />
          <Route path="/resellers/add" element={<ResellerAdd />} />

          <Route path="/legalDocuments" element={<LegalDocuments />} />
          <Route path="/legalDocuments/detail/:id" element={<LegalDocumentsDetail />} />
          <Route path="/legalDocuments/add" element={<LegalDocumentsAdd />} />

          <Route path="/productsearch" element={<ProductSearch />} />

          <Route path="/users/allUsers" element={<AllUsers />} />
          <Route path="/users/edit/:id" element={<UserEdit />} />
          <Route path="/users/openRegistrations" element={<OpenRegistrations />} />
          <Route path="/users/WaitForEmailVerifications" element={<WaitForEmailVerifications />} />

          <Route path="/videoCourses/overview" element={<AllCourses />} />
          <Route path="/videoCourses/edit/:id" element={<CourseEdit />} />

          <Route path="/licences/byDevice" element={<DeactivateByDevice />} />
          <Route path="/licences/byOrder" element={<ByOrderNumber />} />
          <Route path="/licences/byCompany" element={<ByCompany />} />
          <Route path="/licences/byCompany/assign" element={<ByCompanyAssign />} />
          <Route path="/licences/ordernumber/detail/:id" element={<PaidLicencesDetails />} />
          <Route path="/licences/MyGold" element={<MyGold />} />
          <Route path="/licences/MyGold/assign" element={<MyGoldAssign />} />

          <Route path="/installedbase/byCompany" element={<EquipmentByCompany/>} />
          <Route path="/installedbase/byCompany/assign"element={<EquipmentByCompanyAssign/>} />

          <Route path="/software/cockpitcloud" element={<CockpitCloudAreasOverview />} />

          <Route path="/serviceRequests" element={<ServiceRequests/>} />
          <Route path="/serviceRequests/details/:id" element={<ServiceRequestsDetail/>} />
          
          <Route path="/help/overview" element={<HelpOverview />} />
          <Route path="/help/createOrEdit/:id" element={<HelpCreateOrEdit />} />

          <Route path="/settings/reminder" element={<SettingsReminder />} />

          <Route path="/highlevel/maintenance" element={<Maintenance />} />
          <Route path="/highlevel/maintenance/add" element={<MaintenanceMessageFormAdd />} />
          <Route path="/highlevel/maintenance/details/:id" element={<MaintenanceMessageFormEdit />} />
          <Route path="/highlevel/allclaims" element={<AllClaims />} />
          <Route path="/highlevel/emailTriggerRules" element={<EmailTriggerRulesOverview />} />
          <Route path="/highlevel/emailTriggerRules/createOrEdit/:id" element={<EmailTriggerRulesCreateOrEdit />} />
          <Route path="/highlevel/claims/edit/:id" element={<ClaimsEdit />} />
          <Route path="/highlevel/logs" element={<Logs />} />
          <Route path="/highlevel/logs/details/:id" element={<LogsDetails />} />
          <Route path="/highlevel/expertSettings" element={<ExpertSettings />} />
          <Route path="/highlevel/reporting" element={<Reporting />} />

          <Route path="/imprint" element={<Imprint />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/thirdParty" element={<ThirdParty />} />

          <Route path="*" element={<></>} />
        </Route>

        <Route path="*" element={<></>} />

      </Routes>
    </AnimatePresence>
  );
};
