import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { Chip, IconButton, Tooltip } from "@mui/material";
import { IoPencilSharp } from "react-icons/io5";
import { useState } from "react";
import { getTranslation } from "../../../../../Infrastructure/Internationalisation/TranslationService";
import { UserOverviewItem, UserState } from "../../AllUsers/Models/UserOverviewItem";

const getStateColor = (state: UserState) => {
    switch (state) {
        case UserState.IsApproved:
            return 'success';
        case UserState.IsDeleted:
            return 'error';
        case UserState.WaitingApproval:
            return 'info';
        case UserState.VerifyEmail:
            return 'default';
        default:
            return 'default';
    }
}

export const useWaitForEmailVerificationTableColumnDefinitions = (language: string) => {
    const [selectedUserId, setSelectedUserId] = useState<number>(0);
    const [selectedUserEmail, setSelectedUserEmail] = useState<string>("");
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const openMenu = Boolean(anchorEl);
   
    const handleClick = (event: React.MouseEvent<HTMLButtonElement | HTMLDivElement>, userId: number, email: string) => {
        setSelectedUserId(userId);
        setSelectedUserEmail(email);
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const columns: GridColDef[] = [
        {
            field: 'UserId',
            headerName: "Id",
            flex: 0,
            minWidth: 0,
        },
        {
            field: 'UserLastName',
            headerName: getTranslation("UserLastName"),
            flex: 1,
            minWidth: 150,
            valueGetter: (params:  GridRenderCellParams<any, UserOverviewItem, any>) => params.row.UserLastName
        },
        {
            field: 'UserFirstName',
            headerName: getTranslation("UserFirstName"),
            flex: 1,
            minWidth: 150,
            valueGetter: (params:  GridRenderCellParams<any, UserOverviewItem, any>) => params.row.UserFirstName
        },
        {
            field: 'Email',
            headerName: getTranslation("UserEmail"),
            flex: 1,
            minWidth: 150,
            renderCell: (params: GridRenderCellParams<any, UserOverviewItem, any>) => (
                <Chip label={params.row.Email} />
            )
        },
        {
            field: 'CompanyName',
            headerName: getTranslation("UserCompanyName"),
            flex: 1,
            minWidth: 150,
            valueGetter: (params:  GridRenderCellParams<any, UserOverviewItem, any>) => params.row.CompanyName
        },
        {
            field: 'Country',
            headerName: getTranslation("UserCountry"),
            flex: 1,
            minWidth: 150,
            valueGetter: (params:  GridRenderCellParams<any, UserOverviewItem, any>) => params.row.UserCountryIso
        },
        {
            field: 'State',
            headerName: getTranslation("UserState"),
            flex: 1,
            minWidth: 150,
            renderCell: (params: GridRenderCellParams<any, UserOverviewItem, any>) => {
                return <Chip label={getTranslation(params.row.State)} color={getStateColor(params.row.State)} />
            },
            valueGetter: (params: GridRenderCellParams<any, UserOverviewItem, any>) => getTranslation(params.row.State)
        },
        {
            field: '',
            headerName: '',
            flex: 0.1,
            minWidth: 100,
            align: 'right',
            renderCell: (params: GridRenderCellParams<any, UserOverviewItem, any>) => (
                <strong>
                     <Tooltip title={getTranslation("CommonEdit")}>
                        <IconButton onClick={(e) => handleClick(e, params.row.UserId, params.row.Email)} color={'darky'}>
                            <IoPencilSharp />
                        </IconButton>
                    </Tooltip>
                </strong>
            ),
            sortable: false,
            filterable: false
        },
    ];

    
    return {
        columns: columns as GridColDef[],
        anchorEl,
        openMenu,
        handleClose,
        selectedUserId,
        selectedUserEmail,
    }
}