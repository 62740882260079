export interface UserOverviewItem {
  UserId: number,
  UserLastName: string,
  UserFirstName: string,
  CompanyName: string,
  Email: string,
  State: UserState,
  IsoCode: string,
  UserCountryIso: string,
}

export enum UserState {
  VerifyEmail = "VerifyEmail",
  WaitingApproval = "WaitingApproval",
  IsApproved = "IsApproved",
  IsDeleted = "IsDeleted"
}
